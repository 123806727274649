import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import firebase from '../../Firebase/firebase'
import UpdateUnit from '../UpdateUnits/UpdateUnits'
import CreateUnits from '../CreateUnit/CreateUnit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import DeleteModal from '../DeleteModal/DeleteModal'
import { Unit } from '../../Interface/Interface'
import { Button } from '@material-ui/core'
import '../Models/Models.css'

interface Props {
	units: Unit[]
}
const Units = (props: Props) => {
	const { units } = props
	const unitsCollection = firebase.firestore().collection('Units')
	const history = useHistory()
	const [isUpdateModelOpen, setIsUpdateModelOpen] = useState(false)
	const [isCreateModelOpen, setIsCreateModelOpen] = useState(false)
	const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false)
	const [currentUnit, setCurrentUnit]: any = useState()
	const [userID, setUserID] = useState('')

	useEffect(() => {
		;(async () => {
			const auth = await firebase.auth().onAuthStateChanged((user: any) => {
				if (user) {
					setUserID(user.email)
				} else {
					history.push('/login')
				}
			})
		})()
	}, [])

	//DELETE FUNCTION
	const deleteUnit = (unit: any) => {
		unitsCollection
			.doc(unit.uid)
			.delete()
			.catch((err) => {
				console.error(err)
			})
	}
	const [searchValue, setSearchValue] = useState('')
	return (
		<div className='grid-x small-24'>
			<div className='grid-x small-24 align-justify  align-middle'>
				<div style={{ fontSize: '13px' }} className='small-6'>
					{userID}
				</div>
				<h5 style={{ fontSize: '15px' }} className='small-6'>
					Units List
				</h5>
				<div className=' grid-x small-6 align-right'>
					<input
						type='text'
						onChange={(e) => setSearchValue(e.target.value)}
						className='search-bar small-24'
						placeholder='Search Here'
					/>
				</div>
				<Button
					className='large-button'
					onClick={() => {
						setIsCreateModelOpen(true)
					}}
				>
					Create Unit
				</Button>
			</div>

			<table className='table-scroll'>
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Symbol</th>
						<th>Parameters</th>
						<th style={{ textAlign: 'center' }}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{units.length !== 0
						? units
								.filter((unit: any) => {
									if (
										unit.unitID
											.toString()
											.toLowerCase()
											.includes(searchValue.toLowerCase().trim()) ||
										unit.unitName
											.toString()
											.toLowerCase()
											.includes(searchValue.toLowerCase().trim()) ||
										unit.unitSymbol
											.toString()
											.toLowerCase()
											.includes(searchValue.toLowerCase().trim()) ||
										unit.parameter
											.toString()
											.toLowerCase()
											.includes(searchValue.toLowerCase().trim())
									) {
										return unit
									}
								})
								.map((unit: any) => (
									<tr key={unit.id}>
										<td>{unit.unitID}</td>
										<td>{unit.unitName}</td>
										<td>{unit.unitSymbol}</td>
										<td>{unit.parameter}</td>
										<td className='grid-x small-24'>
											<div className='auto cell'></div>
											<button
												onClick={() => {
													setCurrentUnit(unit)
													setIsUpdateModelOpen(true)
												}}
												className=' action-btn'
											>
												<EditIcon className='color-blue' />
											</button>
											<button
												onClick={() => {
													{
														setCurrentUnit(unit)
														setIsDeleteModelOpen(true)
													}
												}}
												className='ml-xs action-btn'
											>
												<DeleteForeverIcon className='color-red' />
											</button>
											<div className='auto cell'></div>
										</td>
									</tr>
								))
						: 'Units Not Found'}
				</tbody>
			</table>
			<div className='show-popup'>
				{isUpdateModelOpen && (
					<UpdateUnit
						unit={currentUnit}
						setIsUpdateModelOpen={setIsUpdateModelOpen}
					/>
				)}
			</div>
			<div className='show-popup'>
				{isCreateModelOpen && (
					<CreateUnits
						newUnitID={
							units.length > 0
								? Math.max(...units.map((unit) => unit.unitID)) + 1
								: 0
						}
						setIsCreateModelOpen={setIsCreateModelOpen}
					/>
				)}
			</div>
			<div className=''>
				{isDeleteModelOpen && (
					<DeleteModal
						setModelOpen={setIsDeleteModelOpen}
						yesButtonAction={() => deleteUnit(currentUnit)}
					/>
				)}
			</div>
		</div>
	)
}

export default Units

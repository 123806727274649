import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import firebase from '../../Firebase/firebase'

import Logo from '../../Images/logo.svg'
import Device from '../../Images/models.svg'
import events from '../../Images/events.svg'
import reports from '../../Images/reports.svg'

import Models from '../Models/Models'
import Units from '../Units/Units'
import Sensors from '../Sensors/Sensors'
import { useHistory } from 'react-router-dom'

const Dashboard = () => {
	const [units, setUnits]: any = useState([])
	const [models, setModels]: any = useState([])
	const [sensors, setSensors]: any = useState([])
	const unitsCollection = firebase.firestore().collection('Units')
	const modelsCollection = firebase.firestore().collection('Models')
	const sensorsCollection = firebase.firestore().collection('Sensors')

	useEffect(() => {
		;(async () => {
			await firebase.auth().onAuthStateChanged(async (user: any) => {
				if (user) {
					console.log('Login success')
					await unitsCollection.onSnapshot((querySnapshot) => {
						const localUnits: any = []
						querySnapshot.forEach((doc: any) => {
							localUnits.push({ ...doc.data(), uid: doc.id })
						})
						setUnits(localUnits)
					})
					await modelsCollection.onSnapshot((querySnapshot) => {
						const localModals: any = []
						querySnapshot.forEach((doc: any) => {
							localModals.push({ ...doc.data(), uid: doc.id })
						})
						setModels(localModals)
					})
					await sensorsCollection.onSnapshot((querySnapshot) => {
						const localSensors: any = []
						querySnapshot.forEach((doc: any) => {
							localSensors.push({ ...doc.data(), uid: doc.id })
						})
						setSensors(localSensors)
					})
				} else {
					history.push('/login')
				}
			})
		})()
	}, [])

	const history = useHistory()
	const logOut = () => {
		firebase.auth().signOut()
		history.push('/login')
	}

	const [page, setPage] = useState(1)

	const menuOptions = [
		{
			title: 'Models',
			icon: Device,
		},
		{
			title: 'Units',
			icon: events,
		},
		{
			title: 'Sensors',
			icon: reports,
		},
	]
	return (
		<div>
			<div id='container' className='container grid-x small-24'>
				<div id='sidebar' className='sidebar cell large-3 medium-2 small-3'>
					<div className='img-container grid-x small-24 align-center'>
						<img
							id='miigo-logo'
							className=' small-20 show-for-large hide-for-small miigo-logo'
							src={Logo}
							alt='dgdf'
						/>
						<svg
							className='hide-for-large img-container'
							width='35'
							height='20'
							viewBox='0 0 35 20'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								fill-rule='evenodd'
								clip-rule='evenodd'
								d='M29.5915 1.66991C29.2664 1.1692 29.4086 0.499636 29.9093 0.174526C30.41 -0.150584 31.0795 -0.00838889 31.4046 0.492182C32.2907 1.85412 32.9882 3.34961 33.4595 4.94145C33.9165 6.48492 34.1618 8.11344 34.1618 9.79287C34.1618 11.503 33.9088 13.1572 33.4382 14.7199C32.9515 16.3358 32.2316 17.8514 31.3182 19.227C30.9883 19.7253 30.3168 19.8618 29.8186 19.5321C29.3203 19.2023 29.1836 18.5308 29.5135 18.0325C30.31 16.8329 30.9379 15.511 31.3625 14.1012C31.7724 12.74 31.993 11.2937 31.993 9.79287C31.993 8.3133 31.7801 6.89 31.3838 5.55161C30.9724 4.16189 30.364 2.85723 29.5915 1.66991ZM4.6483 18.0325C4.97821 18.5308 4.84153 19.2023 4.34336 19.5321C3.84504 19.8618 3.17353 19.7253 2.84376 19.227C1.93021 17.8514 1.21031 16.3358 0.723706 14.7199C0.253037 13.1572 0 11.503 0 9.79287C0 8.11344 0.24542 6.48492 0.702267 4.94145C1.17364 3.34961 1.87111 1.85412 2.75716 0.492182C3.08241 -0.00838889 3.75195 -0.150584 4.25252 0.174526C4.75324 0.499636 4.89541 1.1692 4.5703 1.66991C3.7978 2.85723 3.18946 4.16189 2.77803 5.55161C2.38184 6.89 2.169 8.3133 2.169 9.79287C2.169 11.2937 2.38946 12.74 2.79934 14.1012C3.22388 15.511 3.85181 16.8329 4.6483 18.0325Z'
								fill='#666666'
							/>
							<path
								fill-rule='evenodd'
								clip-rule='evenodd'
								d='M8.85976 15.6761C9.20829 16.1627 9.09643 16.84 8.60983 17.1885C8.12322 17.537 7.44606 17.425 7.09753 16.9384C6.36692 15.9192 5.78694 14.7772 5.39187 13.5457C5.00992 12.3556 4.80371 11.0942 4.80371 9.79293C4.80371 8.51732 5.00033 7.28331 5.36508 6.11996C5.74294 4.91487 6.29936 3.79198 7.00247 2.7828C7.34408 2.29154 8.01941 2.17026 8.51068 2.51173C9.00194 2.85334 9.12323 3.52867 8.78176 4.01993C8.20009 4.85464 7.74141 5.77821 7.43238 6.76398C7.13379 7.7166 6.97271 8.73382 6.97271 9.79293C6.97271 10.8788 7.14027 11.9176 7.45071 12.8848C7.77258 13.888 8.25186 14.828 8.85976 15.6761ZM25.3813 4.01993C25.0397 3.52867 25.161 2.85334 25.6522 2.51173C26.1435 2.17026 26.8188 2.29154 27.1604 2.7828C27.8637 3.79198 28.4201 4.91487 28.7978 6.11996C29.1626 7.28331 29.3593 8.51732 29.3593 9.79293C29.3593 11.0942 29.153 12.3556 28.7712 13.5457C28.376 14.7772 27.7961 15.9192 27.0655 16.9384C26.717 17.425 26.0397 17.537 25.5531 17.1885C25.0665 16.84 24.9546 16.1627 25.3031 15.6761C25.9112 14.828 26.3903 13.888 26.7123 12.8848C27.0226 11.9176 27.1903 10.8788 27.1903 9.79293C27.1903 8.73382 27.0293 7.7166 26.7305 6.76398C26.4215 5.77821 25.9628 4.85464 25.3813 4.01993Z'
								fill='#666666'
							/>
							<path
								fill-rule='evenodd'
								clip-rule='evenodd'
								d='M13.3299 13.0985C13.7251 13.5453 13.6835 14.2281 13.2367 14.6234C12.7898 15.0188 12.1069 14.9772 11.7115 14.5303C11.142 13.8849 10.6871 13.1384 10.3788 12.323C10.0799 11.5319 9.91602 10.6788 9.91602 9.79292C9.91602 8.9396 10.0686 8.11545 10.3483 7.34661C10.6375 6.55097 11.0612 5.82346 11.5908 5.19228C11.9674 4.74234 12.6321 4.67548 13.0901 5.036L13.0904 5.03558L17.0811 8.18104L21.0719 5.03558C21.541 4.66675 22.2201 4.74728 22.5902 5.2147C23.1111 5.8408 23.5282 6.56042 23.8141 7.34661C24.0936 8.11545 24.2462 8.9396 24.2462 9.79292C24.2462 10.6788 24.0825 11.5319 23.7835 12.323C23.4753 13.1384 23.0203 13.8849 22.4507 14.5303C22.0554 14.9772 21.3724 15.0188 20.9256 14.6234C20.4789 14.2281 20.4372 13.5453 20.8325 13.0985C21.2265 12.6519 21.5425 12.1319 21.7585 11.5603C21.9644 11.0155 22.0773 10.4199 22.0773 9.79292C22.0773 9.18671 21.9726 8.61122 21.7806 8.08371C21.7019 7.86721 21.6083 7.65763 21.5009 7.45551L17.766 10.3991C17.3818 10.7132 16.8182 10.7309 16.4119 10.4115L12.6613 7.45551C12.554 7.65763 12.4603 7.86721 12.3816 8.08371C12.1897 8.61122 12.085 9.18671 12.085 9.79292C12.085 10.4199 12.1978 11.0155 12.4038 11.5603C12.6199 12.132 12.9359 12.6521 13.3299 13.0985Z'
								fill='#666666'
							/>
						</svg>
					</div>

					<div className='menu-bar grid-y tab-list'>
						{menuOptions.map((option: any, index: number) => {
							return (
								<label
									onClick={() => setPage(index + 1)}
									className={
										page === index + 1
											? 'active-tab menu cell small-3 grid-x align-center hover-border'
											: 'menu cell small-3 grid-x align-center hover-border'
									}
								>
									<img
										src={option.icon}
										className='small-3 tab-trigger menu-icons'
									/>
									<div className='text-bold small-10 grid-x align-middle hide-for-medium-only hide-for-small-only'>
										{option.title}
									</div>
								</label>
							)
						})}
					</div>

					<label
						onClick={logOut}
						htmlFor='tab3'
						className='menu cell small-3 grid-x align-center hover-border'
					>
						<span
							className='small-3 tab-trigger menu-icons'
							style={{ paddingTop: '2vh' }}
						>
							<svg
								width='20'
								height='20'
								viewBox='0 0 20 20'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M19.9362 8.84816C19.8937 8.74648 19.8328 8.65398 19.7553 8.57648L17.2562 6.07731C16.9303 5.75231 16.4037 5.75231 16.0778 6.07731C15.752 6.40313 15.752 6.93063 16.0778 7.25563L17.1553 8.33312H12.5003C12.0395 8.33312 11.667 8.70644 11.667 9.16644C11.667 9.62644 12.0395 9.99976 12.5003 9.99976H17.1553L16.0778 11.0773C15.752 11.4031 15.752 11.9306 16.0778 12.2556C16.2403 12.4189 16.4536 12.4998 16.667 12.4998C16.8803 12.4998 17.0937 12.4189 17.2562 12.2556L19.7553 9.7564C19.8328 9.67972 19.8937 9.58722 19.9362 9.48472C20.0203 9.28148 20.0203 9.05148 19.9362 8.84816Z'
									fill='#3F5785'
								/>
								<path
									d='M14.1666 11.6667C13.7058 11.6667 13.3333 12.04 13.3333 12.5V16.6666H9.99998V3.33331C9.99998 2.96581 9.7583 2.64082 9.4058 2.535L6.51163 1.66668H13.3333V5.83335C13.3333 6.29335 13.7058 6.66667 14.1666 6.66667C14.6274 6.66667 14.9999 6.29335 14.9999 5.83335V0.833358C14.9999 0.37332 14.6274 0 14.1666 0H0.833319C0.803319 0 0.776639 0.0125 0.747499 0.0158203C0.708319 0.02 0.672499 0.0266406 0.634999 0.0358202C0.547499 0.0583202 0.468319 0.0924998 0.394179 0.13914C0.375859 0.15082 0.353359 0.15164 0.335859 0.164961C0.32914 0.17 0.32664 0.179179 0.31996 0.184179C0.22914 0.25582 0.15332 0.34332 0.0983201 0.447499C0.0866405 0.469999 0.0841405 0.494179 0.0749999 0.517499C0.0483202 0.580819 0.0191797 0.642499 0.00917967 0.712499C0.00499999 0.737499 0.0125 0.760819 0.0116797 0.784999C0.0108594 0.801678 0 0.816678 0 0.833319V17.5C0 17.8975 0.28082 18.2391 0.669999 18.3166L9.0033 19.9833C9.05748 19.995 9.11248 20 9.16662 20C9.35744 20 9.54494 19.9342 9.69494 19.8108C9.88744 19.6525 9.99994 19.4166 9.99994 19.1666V18.3333H14.1666C14.6274 18.3333 14.9999 17.96 14.9999 17.5V12.5C14.9999 12.04 14.6274 11.6667 14.1666 11.6667Z'
									fill='#3F5785'
								/>
							</svg>
						</span>
						<div className='small-10 grid-x align-middle hide-for-medium-only hide-for-small-only'>
							Logout
						</div>
					</label>
				</div>
				{/* {{!-- Dashboard MenuBar --}} */}
				<div className='dashboard-container cell large-21 medium-22 small-21 grid-container'>
					{/* {{!-- Main Section --}} */}
					<div className='dashboard-main grid-x small-24 dashboard-header'>
						<div
							className='grid-x small-24'
							style={{ background: '', opacity: '1' }}
						>
							{page === 1 && <Models models={models} units={units} />}
							{page === 2 && <Units units={units} />}
							{page === 3 && <Sensors sensors={sensors} units={units} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Dashboard

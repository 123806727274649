import React, { useEffect } from 'react'
import { AuthProvider } from '../../Functions/Auth/Auth'
import Login from '../Login/Login'
import Dashboard from '../Dashboard/Dashboard'
import './App.css'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom'
const App = () => {
	return (
		<>
			<AuthProvider>
				<Router>
					<Switch>
						<Route path='/login' exact>
							<Login />
						</Route>
						<Route path='/dashboard' exact>
							<Dashboard />
						</Route>
						<Route path='/'>
							<Redirect to='/login' />
						</Route>
					</Switch>
				</Router>
			</AuthProvider>
		</>
	)
}

export default App

//DOCS: https://firebase.google.com/docs/firestore/

// {/* <Welcome /> */}
//         // <Login />
//         {/* {get ? <GetFirebase /> : <SnapshotFirebase />} */}
//         {/* <SnapshotFirebaseAdvanced /> */}

import React, { useState, useEffect } from 'react'
import '../Models/Models.css'
import { useHistory } from 'react-router-dom'
import firebase from '../../Firebase/firebase'
import UpdateSensor from '../UpdateSensor/UpdateSensor'
import CreateSensors from '../CreateSensor/CreateSensor'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import DeleteModal from '../DeleteModal/DeleteModal'
import { Button } from '@material-ui/core'
interface Props {
	sensors: any
	units: any
}
const Sensors = (props: Props) => {
	const { sensors, units } = props
	const history = useHistory()
	const [isSensorUpdateModelOpen, setIsSensorUpdateModelOpen] = useState(false)
	const [isCreateSensorModelOpen, setIsCreateSensorModelOpen] = useState(false)
	const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false)
	const [currentSensor, setCurrentSensor]: any = useState()
	const [userID, setUserID] = useState('')
	const sensorsCollection = firebase.firestore().collection('Sensors')

	useEffect(() => {
		;(async () => {
			const auth = await firebase.auth().onAuthStateChanged((user: any) => {
				if (user) {
					setUserID(user.email)
				} else {
					history.push('/login')
				}
			})
		})()
	}, [])

	//DELETE FUNCTION
	const deleteSensor = (sensor: any) => {
		sensorsCollection
			.doc(sensor.uid)
			.delete()
			.then(() => {})
			.catch((err) => {
				console.error(err)
			})
	}
	const [searchValue, setSearchValue] = useState('')
	return (
		<div className='grid-x small-24'>
			<div className='grid-x small-24 align-justify  align-middle'>
				<div style={{ fontSize: '13px' }} className='small-6'>
					{userID}
				</div>
				<h5 style={{ fontSize: '15px' }} className='small-6'>
					Sensors List
				</h5>
				<div className=' grid-x small-6 align-right'>
					<input
						type='text'
						onChange={(e) => setSearchValue(e.target.value)}
						className='search-bar small-24'
						placeholder='Search Here'
					/>
				</div>
				<Button
					className='large-button'
					onClick={() => {
						setIsCreateSensorModelOpen(true)
					}}
				>
					Create Sensor
				</Button>
			</div>

			<table className='table-scroll'>
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Units</th>
						<th>Parameters</th>
						<th style={{ textAlign: 'center' }}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{sensors.length !== 0
						? sensors
								.filter((sensor: any) => {
									if (
										sensor.sensorID
											.toString()
											.toLowerCase()
											.includes(searchValue.toLowerCase().trim()) ||
										sensor.sensorName
											.toString()
											.toLowerCase()
											.includes(searchValue.toLowerCase().trim()) ||
										sensor.parameter
											.toString()
											.toLowerCase()
											.includes(searchValue.toLowerCase().trim())
									) {
										return sensor
									}
								})
								.map((sensor: any) => (
									<tr key={sensor.id}>
										<td>{sensor.sensorID}</td>
										<td>{sensor.sensorName}</td>
										<td>
											{sensor.units.map((unit: any, index: number) => {
												return (
													<span>
														{units[
															units
																.map((symbol: any) => symbol.unitID)
																.indexOf(unit)
														]?.unitSymbol &&
															(index !== 0 ? ', ' : '') +
																units[
																	units
																		.map((symbol: any) => symbol.unitID)
																		.indexOf(unit)
																]?.unitSymbol}
													</span>
												)
											})}
										</td>
										<td>{sensor.parameter}</td>
										<td className='grid-x small-24'>
											<div className='auto cell'></div>
											<button
												onClick={() => {
													setCurrentSensor(sensor)
													setIsSensorUpdateModelOpen(true)
												}}
												className=' action-btn'
											>
												<EditIcon className='color-blue' />
											</button>
											<button
												onClick={() => {
													{
														setCurrentSensor(sensor)
														setIsDeleteModelOpen(true)
													}
												}}
												className='ml-xs action-btn'
											>
												<DeleteForeverIcon className='color-red' />
											</button>
											<div className='auto cell'></div>
										</td>
									</tr>
								))
						: 'Sensors Not Found'}
				</tbody>
			</table>

			<div className='show-popup'>
				{isSensorUpdateModelOpen && (
					<UpdateSensor
						sensor={currentSensor}
						setIsSensorUpdateModelOpen={setIsSensorUpdateModelOpen}
						units={units}
					/>
				)}
			</div>
			<div className='show-popup'>
				{isCreateSensorModelOpen && (
					<CreateSensors
						setIsCreateSensorModelOpen={setIsCreateSensorModelOpen}
						newSensorID={
							sensors.length > 0
								? Math.max(...sensors.map((sensor: any) => sensor.sensorID)) + 1
								: 1
						}
						units={units}
					/>
				)}
			</div>
			<div className=''>
				{isDeleteModelOpen && (
					<DeleteModal
						setModelOpen={setIsDeleteModelOpen}
						yesButtonAction={() => deleteSensor(currentSensor)}
					/>
				)}
			</div>
		</div>
	)
}
export default Sensors

import React, { useState, useEffect } from 'react'
import firebase from '../../Firebase/firebase'
import Modal from '../Modal/Modal'
import { Input, MenuItem, Select } from '@material-ui/core'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

interface Props {
	setIsSensorUpdateModelOpen(isSensorUpdateModelOpen: boolean): void
	sensor: any
	units: any[]
}
const UpdateSensor = (props: Props) => {
	const { sensor, setIsSensorUpdateModelOpen, units } = props
	const [localSensor, setLocalSensor] = useState(sensor)
	const sensorsCollection = firebase.firestore().collection('Sensors')
	const ITEM_HEIGHT = 48
	const ITEM_PADDING_TOP = 8
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 500,
			},
		},
	}

	const schema = yup.object().shape({
		sensorName: yup
			.string()
			.required('Unit Symbol is required')
			.default(localSensor.sensorName),
		parameter: yup
			.string()
			.default(localSensor.parameter)
			.matches(/^[a-zA-Z\s]+$/, 'Parameter should contain alphabates only'),
		units: yup.array(
			yup
				.number()
				.default(localSensor.units[0])
				.required('Select atleast one unit')
		),
	})

	const {
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver<any>(schema),
		shouldFocusError: true,
	})

	const onSubmit = () => {
		sensorsCollection
			.doc(localSensor.uid)
			.update({
				sensorName: localSensor.sensorName,
				sensorGivenName: localSensor.sensorGivenName,
				units: localSensor.units,
				parameter: localSensor.parameter,
			})
			.then(() => {
				setIsSensorUpdateModelOpen(false)
			})
			.catch((err) => {
				setIsSensorUpdateModelOpen(false)
				console.error(err)
			})
		//push to db
	}

	return (
		<Modal
			onClose={() => {
				setIsSensorUpdateModelOpen(false)
			}}
		>
			<div className='popupbackgroud grid-x small-22 align-center'>
				<h4>Update Sensor</h4>
				<div className='inputBox grid-x small-23 align-center '>
					<div className='inputBox grid-x small-23 cust-container'>
						<label className='grid-x small-24 align-left cust-label'>
							Sensor Name
						</label>
						<input
							className='small-24 gap-container'
							id='standard-search'
							type='text'
							value={localSensor.sensorName}
							onChange={(e) => {
								setLocalSensor({
									...localSensor,
									sensorName: e.target.value,
								})
								setValue('sensorName', e.target.value, {
									shouldValidate: true,
								})
							}}
						/>
						{errors?.sensorName ? (
							<p className='error-message error-message-registration'>
								{errors.sensorName.message}
							</p>
						) : (
							''
						)}
						<label className='grid-x small-24 align-left cust-label'>
							Sensor Units
						</label>
						<Select
							className='gap-container'
							multiple
							value={localSensor.units}
							onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
								setLocalSensor({
									...localSensor,
									units: event.target.value as number[],
								})
								setValue('units', event.target.value as number[], {
									shouldValidate: true,
								})
							}}
							input={<Input />}
							renderValue={(selected) => (selected as number[]).join(', ')}
							MenuProps={MenuProps}
						>
							{units.map((unit: any) => (
								<MenuItem key={unit.unitID} value={parseInt(unit.unitID)}>
									{unit.unitSymbol}
								</MenuItem>
							))}
						</Select>

						{errors?.units ? (
							<p className='error-message error-message-registration'>
								{errors.units.message}
							</p>
						) : (
							''
						)}
						<label className='grid-x small-24 align-left cust-label'>
							Sensor Parameter
						</label>
						<input
							className='small-24 gap-container'
							id='standard-search'
							type='text'
							value={localSensor.parameter}
							onChange={(e) => {
								setLocalSensor({
									...localSensor,
									parameter: e.target.value,
								})
								setValue('parameter', e.target.value, {
									shouldValidate: true,
								})
							}}
						/>
						{errors?.parameter ? (
							<p className='error-message error-message-registration'>
								{errors.parameter.message}
							</p>
						) : (
							''
						)}
					</div>
					<div className='popup-buttons grid-x small-24'>
						<button
							className='start-stop__button-green forgot-pass'
							onClick={handleSubmit(onSubmit)}
						>
							Update Sensor
						</button>
						<button
							className=' start-stop__button-red forgot-pass'
							onClick={() => setIsSensorUpdateModelOpen(false)}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default UpdateSensor

import React, { useEffect, useState } from 'react'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import './Modal.css'
interface Props {
	children?: React.ReactNode
	height?: number
	isCloseEnabled?: boolean
	onClose: () => void
}
const Modal = (props: Props) => {
	const { children, height = 510, isCloseEnabled = true, onClose } = props
	const [modalScale, setModalScale] = useState(1)

	const closeModal = () => {
		onClose()
	}
	return (
		<div
			style={{
				display: 'block',
			}}
		>
			<div
				className='modal--background'
				onClick={() => {
					if (isCloseEnabled) closeModal()
				}}
			></div>
			<div
				style={{
					top: `calc(50vh - ${height / 200 + 40}vh)`,
					transform: `scale(${modalScale})`,
				}}
				className='model-container-data'
			>
				{isCloseEnabled ? (
					<div className='grid-x'>
						<div className='cell auto'></div>
						<div
							className='cell shrink pt-sm pr-sm close-window'
							onClick={() => {
								if (isCloseEnabled) closeModal()
							}}
						>
							<CancelRoundedIcon />
						</div>
					</div>
				) : (
					<div className='p-sm'></div>
				)}
				<div className='grid-x align-middle align-center modal--body'>
					{children}
				</div>
			</div>
		</div>
	)
}
export default Modal

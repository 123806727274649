import React, { useState } from 'react'
import firebase from '../../Firebase/firebase'
import './Login.css'
import '../Dashboard/Dashboard.css'
import img from '../../Images/img.svg'
import logo from '../../Images/logo.svg'

import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const Login = () => {
	const history = useHistory()
	const [errorMessage, setErrorMessage] = useState({
		status: false,
		message: '',
	})

	const [enteredUserData, setEnteredUserData] = useState({
		userEmail: '',
		userPassword: '',
	})
	const schema = yup.object().shape({
		userEmail: yup
			.string()
			.email('Please Enter Valid Email')
			.required('Email is required'),
		userPassword: yup
			.string()
			.required('Password is required')
			.min(6, 'Password Length should be more than six characters'),
	})

	const {
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver<any>(schema),
		shouldFocusError: true,
	})

	const onSubmit = async () => {
		firebase
			.auth()
			.signInWithEmailAndPassword(
				enteredUserData.userEmail,
				enteredUserData.userPassword
			)
			.then(() => {
				console.log('Login Success')
				history.push('/dashboard')
			})
			.catch((err) => {
				console.error(err.code)
				setErrorMessage({
					status: true,
					message: err.code.replace(/[-]/g, ' ').slice(5, err.code.length),
				})
			})
	}

	return (
		<div className='app'>
			<div className='container grid-x small-24'>
				<div className='bg-img cell large-12 medium-24  small-24 grid-x align-middle align-center hide-for-medium-only hide-for-small-only'>
					<img
						src={img}
						alt=''
						id='big-img'
						className='big-img cell medium-24 small-24'
					/>
				</div>
				<div className='login-form-container large-24 medium-24 small-24 grid-x align-middle align-center'>
					<div className='login-form-data large-24 small-20 form '>
						<div className='logo-container grid-x align-center'>
							<img src={logo} alt='Loading' />
						</div>
						<div className='top-area header grid-x'>
							<div className='tab active cell grid-x align-center login small-12'>
								<div
									className='cust-label'
									onClick={() => {
										history.push('/login')
									}}
								>
									Log In
								</div>
							</div>
							{/* <div className='tab cell grid-x align-center signup small-12'>
								<a className='cust-label' href='#signup'>
									Signup
								</a>
							<div className="auto"></div>
							</div> */}
							<div className='cell auto'></div>
						</div>

						<div className='tab-content'>
							<div id='login' className='login-form'>
								<form method='post' action='login/dashboard'>
									<label className='grid-x small-24 align-left cust-label'>
										Email ID
									</label>
									<input
										className='small-24 gap-container'
										type='text'
										// label='unitName'
										value={enteredUserData.userEmail}
										onChange={(e) => {
											setEnteredUserData({
												...enteredUserData,
												userEmail: e.target.value,
											})
											setValue('userEmail', e.target.value, {
												shouldValidate: true,
											})
										}}
									/>
									{errors?.userEmail ? (
										<div className='error-para'>{errors.userEmail.message}</div>
									) : (
										''
									)}
									<label className='grid-x small-24 align-left cust-label'>
										Password
									</label>
									<input
										className='small-24 gap-container'
										type='password'
										// label='unitName'
										value={enteredUserData.userPassword}
										onChange={(e) => {
											setEnteredUserData({
												...enteredUserData,
												userPassword: e.target.value,
											})
											setValue('userPassword', e.target.value, {
												shouldValidate: true,
											})
										}}
									/>
									{errors?.userPassword ? (
										<div className='error-para'>
											{errors.userPassword.message}
										</div>
									) : (
										''
									)}
									<div className='error-para'>{errorMessage.message}</div>
									{/* <div className='forgot-pass cust-label'>
										<a className='cust-label'>Forgot Password?</a>
									</div> */}
									<div className='login-btn-container'>
										<div className='large grid-x small-24 align-center'>
											<Button
												className='large-button login-button'
												onClick={handleSubmit(onSubmit)}
											>
												Login
											</Button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login

import React, { useEffect, useState } from 'react'
import firebase from '../../Firebase/firebase'
import Modal from '../Modal/Modal'
import { Input, MenuItem, Select } from '@material-ui/core'
import { ModelType } from '../../Interface/Interface'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

interface Props {
	models: any
	units: any
	setIsCreateModelModelOpen(isCreateModelOpen: boolean): void
}
const CreateModels = (props: Props) => {
	const { models, setIsCreateModelModelOpen, units } = props
	const calculatedChannelEnables = ['CAL1', 'CAL2']
	const [modeIDErrorMessage, setModelIDErrorMessage] = useState('')
	const modelTypes: string[] = ['Reserved', 'Getway', 'Hygrometer', 'Logger']
	const numberOfChannels: number[] = [0, 1, 2, 3, 4]
	const numberOfCalculatedChannels: number[] = [0, 1, 2]
	const [newModel, setNewModel] = useState<ModelType>({
		modelID: '',
		modelName: '',
		modelType: '',
		noOfChannels: -1,
		noOfCalculatedChannels: -1,
		units: [],
		calculatedChannelUnits: [],
		enabledCalculatedChannels: [],
		maxNoOfLogs: -1,
	})

	const schema = yup.object().shape({
		modelID: yup.string().required('Model ID is required'),
		modelName: yup.string().required('Model Name is required'),
		modelType: yup.string().required('Model Type is required'),
		noOfChannels: yup.number().required('No Of Channels  is required'),
		noOfCalculatedChannels: yup
			.number()
			.required('No Of Calculated Channels  is required'),
		maxNoOfLogs: yup.number().required('Max No Of Logs  is required'),
	})

	const modelsCollection = firebase.firestore().collection('Models')
	const ITEM_HEIGHT = 48
	const ITEM_PADDING_TOP = 8
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 500,
			},
		},
	}
	const {
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver<any>(schema),
		shouldFocusError: true,
	})
	const createNewModel = () => {
		modelsCollection
			.doc()
			.set({
				...newModel,
				enabledCalculatedChannels: [
					newModel.enabledCalculatedChannels.indexOf('CAL1') !== -1,
					newModel.enabledCalculatedChannels.indexOf('CAL2') !== -1,
				],
				calculatedChannelUnits:
					newModel.enabledCalculatedChannels.length === 2
						? [1, 1]
						: newModel.enabledCalculatedChannels.length === 1
						? newModel.enabledCalculatedChannels[0] === 'CAL1'
							? [1]
							: [1]
						: [],
			})
			.catch((err) => {
				console.error(err)
			})
		setIsCreateModelModelOpen(false)
	}
	useEffect(() => {
		if (
			models.filter((model: any) => model.modelID === newModel.modelID).length >
			0
		) {
			setModelIDErrorMessage('Model ID already used.')
		} else {
			setModelIDErrorMessage('')
		}
	}, [newModel.modelID])
	return (
		<Modal onClose={() => setIsCreateModelModelOpen(false)}>
			<div className='popupbackgroud grid-x small-24 align-center'>
				<h4>Create Model</h4>
				<div className='inputBox grid-x small-23 align-center'>
					<div className='inputBox grid-x small-23 cust-container'>
						<label className='grid-x small-24 align-left cust-label'>
							Model ID
						</label>
						<input
							type='text'
							value={newModel.modelID}
							onChange={(e) => {
								setNewModel({ ...newModel, modelID: e.target.value })
								setValue('modelID', e.target.value, {
									shouldValidate: true,
								})
							}}
							className='gap-container small-24'
						/>
						{errors?.modelID ? (
							<p className='error-para'>{errors.modelID.message}</p>
						) : modeIDErrorMessage.length > 0 ? (
							<p className='error-para'>{modeIDErrorMessage}</p>
						) : (
							''
						)}
						<label className='grid-x small-24 align-left cust-label'>
							Model Name
						</label>
						<input
							type='text'
							value={newModel.modelName}
							onChange={(e) => {
								setNewModel({ ...newModel, modelName: e.target.value })
								setValue('modelName', e.target.value, {
									shouldValidate: true,
								})
							}}
							className='gap-container small-24'
						/>
						{errors?.modelName ? (
							<p className='error-para'>{errors.modelName.message}</p>
						) : (
							''
						)}
						<label className='grid-x small-24 align-left cust-label'>
							Model Type
						</label>
						<Select
							className='small-24 select-box gap-container'
							value={newModel.modelType}
							onChange={(e: any) => {
								setNewModel({
									...newModel,
									modelType: e.target.value,
								})
								setValue('modelType', e.target.value, {
									shouldValidate: true,
								})
							}}
							input={<Input />}
							MenuProps={MenuProps}
						>
							{modelTypes.map((option: any) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
						{errors?.modelType ? (
							<p className='error-para'>{errors.modelType.message}</p>
						) : (
							''
						)}
						<label className='grid-x small-24 align-left cust-label'>
							No of Channels
						</label>
						<Select
							className='small-24 select-box gap-container'
							value={
								newModel.noOfChannels === -1 || isNaN(newModel.noOfChannels)
									? ''
									: newModel.noOfChannels
							}
							onChange={(e: any) => {
								setNewModel({
									...newModel,
									noOfChannels: parseInt(e.target.value),
								})
								setValue('noOfChannels', e.target.value, {
									shouldValidate: true,
								})
							}}
							input={<Input />}
							MenuProps={MenuProps}
						>
							{numberOfChannels.map((option: any) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
						{(() => {
							const channelUnitInputBox: any = []
							for (
								let index = 0;
								newModel.noOfChannels > 0 && index < newModel.noOfChannels;
								index++
							) {
								channelUnitInputBox.push(
									<div className='grid-x small-24'>
										<label className='grid-x small-24 align-left cust-label'>
											select Channel{' ' + (index + 1) + ' '} unit
										</label>
										<Select
											className='small-24 select-box gap-container'
											value={newModel.units[index]}
											onChange={(
												event: React.ChangeEvent<{ value: unknown }>
											) => {
												let localUnit: number[] = newModel.units
												localUnit[index] = event.target.value as number
												setNewModel({
													...newModel,
													units: localUnit,
												})
											}}
											input={<Input />}
											renderValue={(selected) => selected as number}
											MenuProps={MenuProps}
										>
											{units.map((unit: any) => (
												<MenuItem
													key={unit.unitID}
													value={parseInt(unit.unitID)}
												>
													{unit.unitSymbol}
												</MenuItem>
											))}
										</Select>
									</div>
								)
							}
							return channelUnitInputBox
						})()}
						{errors?.noOfChannels ? (
							<p className='error-para'>{errors.noOfChannels.message}</p>
						) : (
							''
						)}
						<label className='grid-x small-24 align-left cust-label'>
							No of Calculated Channels
						</label>
						<Select
							className='small-24 select-box gap-container'
							value={
								newModel.noOfCalculatedChannels === -1 ||
								isNaN(newModel.noOfCalculatedChannels)
									? ''
									: newModel.noOfCalculatedChannels
							}
							onChange={(e: any) => {
								if (parseInt(e.target.value) === 0) {
									setNewModel({
										...newModel,
										noOfCalculatedChannels: parseInt(e.target.value),
										enabledCalculatedChannels: [],
									})
								} else {
									setNewModel({
										...newModel,
										noOfCalculatedChannels: parseInt(e.target.value),
									})
								}
								setValue('noOfCalculatedChannels', e.target.value, {
									shouldValidate: true,
								})
							}}
							input={<Input />}
							MenuProps={MenuProps}
						>
							{numberOfCalculatedChannels.map((option: any) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
						{errors?.noOfCalculatedChannels ? (
							<p className='error-para'>
								{errors.noOfCalculatedChannels.message}
							</p>
						) : (
							''
						)}
						{newModel.noOfCalculatedChannels > 1 ? (
							<div className='grid-x small-24'>
								<label className='grid-x small-24 align-left cust-label'>
									Enabled Calculated Channels
								</label>
								<Select
									className='small-24 select-box gap-container'
									multiple
									value={newModel.enabledCalculatedChannels}
									onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
										setNewModel({
											...newModel,
											enabledCalculatedChannels: event.target.value as string[],
										})
									}}
									input={<Input />}
									renderValue={(selected) => (selected as string[]).join(', ')}
									MenuProps={MenuProps}
								>
									{calculatedChannelEnables.map(
										(calculatedChannelEnable: any, index: number) => (
											<MenuItem
												key={(index + 1).toString()}
												value={calculatedChannelEnable}
											>
												{calculatedChannelEnable}
											</MenuItem>
										)
									)}
								</Select>
							</div>
						) : newModel.noOfCalculatedChannels > 0 ? (
							<div className='grid-x small-24'>
								<label className='grid-x small-24 align-left cust-label'>
									Enabled Calculated Channels
								</label>
								<Select
									className='small-24 select-box gap-container'
									value={newModel.enabledCalculatedChannels}
									onChange={(event: any) => {
										setNewModel({
											...newModel,
											enabledCalculatedChannels: [event.target.value],
										})
									}}
									input={<Input />}
									renderValue={(selected) => (selected as string[]).join(', ')}
									MenuProps={MenuProps}
								>
									{calculatedChannelEnables.map(
										(calculatedChannelEnable: any, index: number) => (
											<MenuItem
												key={(index + 1).toString()}
												value={calculatedChannelEnable}
											>
												{calculatedChannelEnable}
											</MenuItem>
										)
									)}
								</Select>
							</div>
						) : (
							<></>
						)}
						{/* {errors?.enabledCalculatedChannels ? (
							<p className='error-para'>
								{errors.enabledCalculatedChannels.message}
							</p>
						) : (
							''
						)} */}
						{/* <input
							type='text'
							value={newModel.enabledCalculatedChannels}
							onChange={(e) => {
								setNewModel({
									...newModel,
									enabledCalculatedChannels: e.target.value,
								})
							}}
							className='gap-container small-24'
						/> */}
						<div className='grid-x small-24'>
							<label className='grid-x small-24 align-left cust-label'>
								Max No of Logs
							</label>
							<input
								type='text'
								value={
									newModel.maxNoOfLogs === -1 || isNaN(newModel.maxNoOfLogs)
										? ''
										: newModel.maxNoOfLogs
								}
								onChange={(e) => {
									setNewModel({
										...newModel,
										maxNoOfLogs: parseInt(e.target.value),
									})
									setValue('maxNoOfLogs', e.target.value, {
										shouldValidate: true,
									})
								}}
								className='gap-container small-24'
							/>
							{errors?.maxNoOfLogs ? (
								<p className='error-para'>{errors.maxNoOfLogs.message}</p>
							) : (
								''
							)}
						</div>
					</div>

					<div className='popup-buttons grid-x small-24'>
						<button
							className=' start-stop__button-green forgot-pass'
							onClick={handleSubmit(createNewModel)}
						>
							Create Model
						</button>
						<button
							className=' start-stop__button-red forgot-pass'
							onClick={() => setIsCreateModelModelOpen(false)}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default CreateModels

import React, { useState, useEffect, Component } from 'react'
import { useHistory } from 'react-router-dom'
import firebase from '../../Firebase/firebase'
import CreateModels from '../CreateModel/CreateModel'
import UpdateModel from '../UpdateModel/UpdateModel'
import DeleteModal from '../DeleteModal/DeleteModal'
import { Button } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import './Models.css'

interface Props {
	models: any
	units: any
}
const Models = (props: Props) => {
	const { models, units } = props
	const history = useHistory()
	const [isModelUpdateModelOpen, setIsModelUpdateModelOpen] = useState(false)
	const [isCreateModelModelOpen, setIsCreateModelModelOpen] = useState(false)
	const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false)
	const [currentModel, setCurrentModel]: any = useState()
	const [currentUserEmailID, setCurrentUserEmailID] = useState('')

	const modelsCollection = firebase.firestore().collection('Models')
	useEffect(() => {
		;(async () => {
			const auth = await firebase.auth().onAuthStateChanged((user: any) => {
				if (user) {
					setCurrentUserEmailID(user.email)
				} else {
					history.push('/login')
				}
			})
		})()
	}, [])

	//DELETE FUNCTION
	const deleteModel = (model: any) => {
		modelsCollection
			.doc(model.uid)
			.delete()
			.then(() => {})
			.catch((err) => {
				console.error(err)
			})
		setIsDeleteModelOpen(false)
	}
	const [searchValue, setSearchValue] = useState('')
	return (
		<div className='grid-x small-24'>
			<div className='grid-x small-24 align-justify  align-middle'>
				<div style={{ fontSize: '13px' }} className='small-6'>
					{currentUserEmailID}
				</div>
				<h5 style={{ fontSize: '15px' }} className='small-6'>
					Models List
				</h5>
				<div className=' grid-x small-6 align-right'>
					<input
						type='text'
						onChange={(e) => setSearchValue(e.target.value)}
						className='search-bar small-24'
						placeholder='Search Here'
					/>
				</div>
				<Button
					className='large-button'
					onClick={() => {
						setIsCreateModelModelOpen(true)
					}}
				>
					Create Model
				</Button>
			</div>

			<table className='table-scroll'>
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Type</th>
						<th>No Of Channels</th>
						<th>No Of Calculated Channels</th>
						<th>Units</th>
						<th>Calculated Channel Units</th>
						<th>Enabled Calculated Channels</th>
						<th>Max No Of Logs</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{models.length !== 0
						? models
								.filter((model: any) => {
									if (
										model.modelID
											.toString()
											.toLowerCase()
											.includes(searchValue.toLowerCase().trim()) ||
										model.modelName
											.toString()
											.toLowerCase()
											.includes(searchValue.toLowerCase().trim()) ||
										model.modelType
											.toString()
											.toLowerCase()
											.includes(searchValue.toLowerCase().trim())
									) {
										return model
									}
								})
								.map((model: any) => (
									<tr key={model.id}>
										<td>{model.modelID}</td>
										<td>{model.modelName}</td>
										<td>{model.modelType}</td>
										<td>{model.noOfChannels}</td>
										<td>{model.noOfCalculatedChannels}</td>
										<td>
											{model.units.map((unit: any, index: number) => {
												return (
													<span>
														{units[
															units
																.map((symbol: any) => symbol.unitID)
																.indexOf(unit)
														]?.unitSymbol &&
															(index !== 0 ? ', ' : '') +
																units[
																	units
																		.map((symbol: any) => symbol.unitID)
																		.indexOf(unit)
																]?.unitSymbol}
													</span>
												)
											})}
										</td>

										<td>
											{model.calculatedChannelUnits.map(
												(unit: number, index: number) => {
													return (
														<span>
															{unit !== -1
																? `${
																		units[
																			units
																				.map((symbol: any) => symbol.unitID)
																				.indexOf(unit)
																		]?.unitSymbol
																  }${
																		model.calculatedChannelUnits[index + 1] ===
																		1
																			? ', '
																			: ''
																  }`
																: ``}
														</span>
													)
												}
											)}
										</td>
										<td>
											{model.enabledCalculatedChannels.map(
												(enabledCalculatedChannel: any, index: number) => {
													return (
														<span>
															{enabledCalculatedChannel === true
																? `CAL${index + 1}${
																		model.enabledCalculatedChannels[
																			index + 1
																		] === true
																			? ', '
																			: ''
																  }`
																: ``}
														</span>
													)
												}
											)}
										</td>
										<td>{model.maxNoOfLogs}</td>
										<td className='grid-x small-24'>
											<button
												onClick={() => {
													setCurrentModel(model)
													setIsModelUpdateModelOpen(true)
												}}
												className=' action-btn'
											>
												<EditIcon className='color-blue' />
											</button>
											<button
												onClick={() => {
													{
														setCurrentModel(model)
														setIsDeleteModelOpen(true)
													}
												}}
												className='ml-xs action-btn'
											>
												<DeleteForeverIcon className='color-red' />
											</button>
											<div className='auto cell'></div>
										</td>
									</tr>
								))
						: 'Models Not Found'}
				</tbody>
			</table>
			<div className='show-popup'>
				{isModelUpdateModelOpen && (
					<UpdateModel
						models={models}
						currentModel={currentModel}
						setIsUpdateModelOpen={setIsModelUpdateModelOpen}
						units={units}
					/>
				)}
			</div>
			<div className='show-popup'>
				{isCreateModelModelOpen && (
					<CreateModels
						models={models}
						units={units}
						setIsCreateModelModelOpen={setIsCreateModelModelOpen}
					/>
				)}
			</div>
			<div className=''>
				{isDeleteModelOpen && (
					<DeleteModal
						setModelOpen={setIsDeleteModelOpen}
						yesButtonAction={() => deleteModel(currentModel)}
					/>
				)}
			</div>
		</div>
	)
}

export default Models

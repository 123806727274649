import React, { useState } from 'react'
import firebase from '../../Firebase/firebase'
import Modal from '../Modal/Modal'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
interface Props {
	newUnitID: number
	setIsCreateModelOpen(createUnits: boolean): void
}
const CreateUnits = (props: Props) => {
	const { newUnitID, setIsCreateModelOpen } = props
	const unitCollection = firebase.firestore().collection('Units')
	const [newUnit, setNewUnit] = useState({
		unitID: newUnitID,
		unitSymbol: '',
		unitName: '',
		parameter: '',
	})
	const schema = yup.object().shape({
		unitSymbol: yup
			.string()
			.required('Unit Symbol is required')
			.matches(/^[a-zA-Z\s]+$/, 'Unit Symbol should contain alphabates only'),
		unitName: yup
			.string()
			.required('Unit Name is required')
			.matches(/^[a-zA-Z\s]+$/, 'Unit Name should contain alphabates only'),
		parameter: yup
			.string()
			.required('Parameter is required')
			.matches(/^[a-zA-Z\s]+$/, 'Parameter should contain alphabates only'),
	})

	const {
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver<any>(schema),
		shouldFocusError: true,
	})

	const onSubmit = () => {
		unitCollection
			.doc()
			.set(newUnit)
			.catch((err) => {
				console.error(err)
			})
		setIsCreateModelOpen(false)
		//push to db
	}

	return (
		<Modal
			onClose={() => {
				setIsCreateModelOpen(false)
			}}
		>
			<div className='popupbackgroud grid-x small-22 align-center'>
				<h4>Create Unit</h4>
				<div className='inputBox grid-x small-23 align-center '>
					<div className='inputBox grid-x small-23 cust-container create-unit'>
						<label className='grid-x small-24 align-left cust-label'>
							Unit Name
						</label>
						<input
							className='small-24 gap-container'
							type='text'
							// label='unitName'
							value={newUnit.unitName}
							onChange={(e) => {
								setNewUnit({
									...newUnit,
									unitName: e.target.value,
								})
								setValue('unitName', e.target.value, {
									shouldValidate: true,
								})
							}}
						/>
						{errors?.unitName ? (
							<p className='error-para'>{errors.unitName.message}</p>
						) : (
							''
						)}
						<label className='grid-x small-24 align-left cust-label'>
							Unit Symbol
						</label>
						<input
							className='small-24 gap-container'
							type='text'
							value={newUnit.unitSymbol}
							onChange={(e) => {
								setNewUnit({
									...newUnit,
									unitSymbol: e.target.value,
								})
								setValue('unitSymbol', e.target.value, {
									shouldValidate: true,
								})
							}}
						/>
						{errors?.unitSymbol ? (
							<p className='error-para'>{errors.unitSymbol.message}</p>
						) : (
							''
						)}
						<label className='grid-x small-24 align-left cust-label'>
							Unit Parameter
						</label>
						<input
							className='small-24 gap-container'
							type='text'
							value={newUnit.parameter}
							onChange={(e) => {
								setNewUnit({
									...newUnit,
									parameter: e.target.value,
								})
								setValue('parameter', e.target.value, {
									shouldValidate: true,
								})
							}}
						/>
						{errors?.parameter ? (
							<p className='error-para'>{errors.parameter.message}</p>
						) : (
							''
						)}
					</div>
					<div className='popup-buttons'>
						<button
							className='start-stop__button-green forgot-pass'
							onClick={handleSubmit(onSubmit)}
						>
							Create Unit
						</button>
						<button
							className=' start-stop__button-red forgot-pass'
							onClick={() => {
								setIsCreateModelOpen(false)
							}}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default CreateUnits

import React, { useState } from 'react'
import firebase from '../../Firebase/firebase'
import Modal from '../Modal/Modal'
import Select from '@material-ui/core/Select'
import { Input, MenuItem } from '@material-ui/core'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

interface PeferencesSensorType {
	sensorID: number
	sensorName: string
	sensorGivenName: string
	units: number[]
	isSensorNew: boolean
	parameter: string
}
interface Props {
	setIsCreateSensorModelOpen(isCreateSensorModelOpen: boolean): void
	newSensorID: number
	units: any
}
const CreateSensors = (props: Props) => {
	const { setIsCreateSensorModelOpen, newSensorID, units } = props
	const ITEM_HEIGHT = 48
	const ITEM_PADDING_TOP = 8
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 500,
			},
		},
	}

	const sensorsCollection = firebase.firestore().collection('Sensors')
	const [selectedPeferencesSensorType, setSelectedPeferencesSensorType] =
		useState<PeferencesSensorType>({
			sensorID: newSensorID,
			sensorName: '',
			sensorGivenName: '',
			units: [],
			isSensorNew: false,
			parameter: '',
		})
	const schema = yup.object().shape({
		sensorName: yup.string().required('Unit Symbol is required'),
		parameter: yup
			.string()
			.matches(/^[a-zA-Z\s]+$/, 'Parameter should contain alphabates only'),
		units: yup.array(yup.number().required('Select atleast one unit')),
	})

	const {
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver<any>(schema),
		shouldFocusError: true,
	})

	const onSubmit = () => {
		sensorsCollection
			.doc()
			.set(selectedPeferencesSensorType)
			.then(() => {
				setIsCreateSensorModelOpen(false)
			})
			.catch((err) => {
				console.error(err)
			})
		//push to db
	}

	return (
		<Modal
			onClose={() => {
				setIsCreateSensorModelOpen(false)
			}}
		>
			<div className='popupbackgroud grid-x small-22 align-center'>
				<h4>Create Sensor</h4>
				<div className='inputBox grid-x small-23 align-center '>
					<div className='inputBox grid-x small-23 cust-container'>
						<label className='grid-x small-24 align-left cust-label'>
							Sensor Name
						</label>
						<input
							className='small-24 gap-container'
							id='standard-search'
							type='text'
							value={selectedPeferencesSensorType.sensorName}
							onChange={(e) => {
								setSelectedPeferencesSensorType({
									...selectedPeferencesSensorType,
									sensorName: e.target.value,
								})
								setValue('sensorName', e.target.value, {
									shouldValidate: true,
								})
							}}
						/>
						{errors?.sensorName ? (
							<p className='error-para'>{errors.sensorName.message}</p>
						) : (
							''
						)}

						<label className='grid-x small-24 align-left cust-label'>
							Select Sensor Units
						</label>
						<Select
							className='gap-container'
							multiple
							value={selectedPeferencesSensorType.units}
							onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
								setSelectedPeferencesSensorType({
									...selectedPeferencesSensorType,
									units: event.target.value as number[],
								})
								setValue('units', event.target.value as number[], {
									shouldValidate: true,
								})
							}}
							input={<Input />}
							renderValue={(selected) => (selected as number[]).join(', ')}
							MenuProps={MenuProps}
						>
							{units.map((unit: any) => (
								<MenuItem key={unit.unitID} value={parseInt(unit.unitID)}>
									{unit.unitSymbol}
								</MenuItem>
							))}
						</Select>

						{errors?.units ? (
							<p className='error-para'>{errors.units.message}</p>
						) : (
							''
						)}
						<label className='grid-x small-24 align-left cust-label'>
							Sensor Parameter
						</label>
						<input
							className='small-24 gap-container'
							id='standard-search'
							type='text'
							value={selectedPeferencesSensorType.parameter}
							onChange={(e) => {
								setSelectedPeferencesSensorType({
									...selectedPeferencesSensorType,
									parameter: e.target.value,
								})
								setValue('parameter', e.target.value, {
									shouldValidate: true,
								})
							}}
						/>
						{errors?.parameter ? (
							<p className='error-para'>{errors.parameter.message}</p>
						) : (
							''
						)}
					</div>
					<div className='popup-buttons grid-x small-24'>
						<button
							className='start-stop__button-green forgot-pass'
							onClick={handleSubmit(onSubmit)}
						>
							Create Sensor
						</button>
						<button
							className=' start-stop__button-red forgot-pass'
							onClick={() => setIsCreateSensorModelOpen(false)}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default CreateSensors

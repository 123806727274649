import React from 'react'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'

interface Props {
	setModelOpen(modelOpen: boolean): void
	yesButtonAction(): void
}

const DeleteModal = (props: Props) => {
	const { yesButtonAction, setModelOpen } = props
	return (
		<div>
			<div
				className='modal--background'
				onClick={() => {
					// if (isCloseEnabled) closeModal()
				}}
			></div>
			<div
				className='grid-x small-24 align-center model-container-data'
				style={{ height: '36vh', top: '24vh' }}
			>
				<div className='grid-x'>
					<div className='cell auto'></div>
					<div
						className='cell shrink pt-sm pr-sm close-window'
						onClick={() => setModelOpen(false)}
					>
						<CancelRoundedIcon />
					</div>
				</div>
				<div className='grid-x align-middle align-center modal--body'>
					<h3>Delete</h3>
				</div>
				<h5 className='grid-x small-24 align-center'>Are You Sure?</h5>
				<div className='grid-x small-24 popup-buttons'>
					<button
						className=' start-stop__button-green forgot-pass'
						onClick={() => {
							yesButtonAction()
							setModelOpen(false)
						}}
					>
						YES
					</button>
					<button
						className=' start-stop__button-red forgot-pass'
						onClick={() => setModelOpen(false)}
					>
						NO
					</button>
				</div>
			</div>
		</div>
	)
}

export default DeleteModal

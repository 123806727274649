import React, { useEffect, useState } from 'react'
import firebase from '../../Firebase/firebase'
import loader from '../../Images/loading.gif'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			setCurrentUser(user)
			setLoading(false)
		})
	}, [])

	if (loading) {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '80vh',
				}}
			>
				<div>
					<img src={loader} width='100px' />
				</div>
			</div>
		)
	}

	return (
		<AuthContext.Provider
			value={{
				currentUser,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

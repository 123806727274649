import React, { useState } from 'react'
import firebase from '../../Firebase/firebase'
import Modal from '../Modal/Modal'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

interface Props {
	unit: any
	setIsUpdateModelOpen(isEditUnitModelOpen: boolean): void
}
const UpdateUnits = (props: Props) => {
	const { unit, setIsUpdateModelOpen } = props
	const [localUnit, setLocalUnit] = useState(unit)
	const unitsCollection = firebase.firestore().collection('Units')

	const schema = yup.object().shape({
		unitSymbol: yup
			.string()
			.default(localUnit.unitSymbol)
			.required('Unit Symbol is required')
			.matches(/^[a-zA-Z\s]+$/, 'Unit Symbol should contain alphabates only'),
		unitName: yup
			.string()
			.default(localUnit.unitName)
			.required('Unit Name is required')
			.matches(/^[a-zA-Z\s]+$/, 'Unit Name should contain alphabates only'),
		parameter: yup
			.string()
			.default(localUnit.parameter)
			.required('Parameter is required')
			.matches(/^[a-zA-Z\s]+$/, 'Parameter should contain alphabates only'),
	})

	const {
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver<any>(schema),
		shouldFocusError: true,
	})

	const onSubmit = () => {
		unitsCollection
			.doc(unit.uid)
			.update({
				unitSymbol: localUnit.unitSymbol,
				unitName: localUnit.unitName,
				parameter: localUnit.parameter,
			})
			.catch((err) => {
				console.error(err)
			})
		setIsUpdateModelOpen(false)
		//push to db
	}
	return (
		<Modal
			onClose={() => {
				setIsUpdateModelOpen(false)
			}}
		>
			<div className='popupbackgroud grid-x small-22 align-center'>
				<h4>Update Unit</h4>
				<div className='inputBox grid-x small-23 align-center '>
					<div className='inputBox grid-x small-23 cust-container create-unit'>
						<label className='grid-x small-24 align-left cust-label'>
							Unit Name
						</label>
						<input
							className='small-24 gap-container'
							id='standard-search'
							type='text'
							value={localUnit.unitName}
							onChange={(e) => {
								setLocalUnit({
									...localUnit,
									unitName: e.target.value,
								})
								setValue('unitName', e.target.value, {
									shouldValidate: true,
								})
							}}
						/>
						{errors?.unitName ? (
							<p className='error-message error-message-registration'>
								{errors.unitName.message}
							</p>
						) : (
							''
						)}
						<label className='grid-x small-24 align-left cust-label'>
							Unit Symbol
						</label>
						<input
							className='small-24 gap-container'
							id='standard-search'
							type='text'
							value={localUnit.unitSymbol}
							onChange={(e) => {
								setLocalUnit({
									...localUnit,
									unitSymbol: e.target.value,
								})
								setValue('unitSymbol', e.target.value, {
									shouldValidate: true,
								})
							}}
						/>
						{errors?.unitSymbol ? (
							<p className='error-message error-message-registration'>
								{errors.unitSymbol.message}
							</p>
						) : (
							''
						)}
						<label className='grid-x small-24 align-left cust-label'>
							Unit Parameter
						</label>
						<input
							className='small-24 gap-container'
							id='standard-search'
							type='text'
							value={localUnit.parameter}
							onChange={(e) => {
								setLocalUnit({
									...localUnit,
									parameter: e.target.value,
								})
								setValue('parameter', e.target.value, {
									shouldValidate: true,
								})
							}}
						/>
						{errors?.parameter ? (
							<p className='error-message error-message-registration'>
								{errors.parameter.message}
							</p>
						) : (
							''
						)}
					</div>
					<div className='popup-buttons'>
						<button
							className='start-stop__button-green forgot-pass'
							onClick={handleSubmit(onSubmit)}
						>
							Update Unit
						</button>
						<button
							className=' start-stop__button-red forgot-pass'
							onClick={() => setIsUpdateModelOpen(false)}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default UpdateUnits

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
	apiKey: 'AIzaSyA1hWYFVYF9NKaDA04McdALcxbX9jh2M8I',
	authDomain: 'miig-cloud.firebaseapp.com',
	databaseURL: 'https://miig-cloud-default-rtdb.firebaseio.com',
	projectId: 'miig-cloud',
	storageBucket: 'miig-cloud.appspot.com',
	messagingSenderId: '159248512646',
	appId: '1:159248512646:web:723f6a9fe3e6d8ac4a4307',
	measurementId: 'G-V8BRM3SFT7',
}

firebase.initializeApp(firebaseConfig)

export default firebase
